import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import ChiroReview1 from "../images/chiro-review-1.png"
import ChiroReview2 from "../images/chiro-review-2.png"
import ChiroReview3 from "../images/chiro-review-3.png"
import ChiroReview4 from "../images/chiro-review-4.png"
import ChiroReview5 from "../images/chiro-review-5.jpg"
import ChiroReview6 from "../images/chiro-review-6.jpg"
import ChiroReview7 from "../images/chiro-review-7.png"
import ChiroReview8 from "../images/chiro-review-8.jpg"
import ChiroReview9 from "../images/chiro-review-9.jpg"
import ChiroReview10 from "../images/chiro-review-10.jpg"
import ChiroReview11 from "../images/chiro-review-11.png"
import ChiroReview12 from "../images/chiro-review-12.png"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";

import image1 from "../images/sport-testing/image1.png"
import image2 from "../images/sport-testing/image2.jpg"
import image3 from "../images/sport-testing/image3.jpg"
import {useCollapse} from "react-collapsed";


function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"text-lg font-bold p-1 flex justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }

            </h2>
            <section  {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

function ReturnToSportTesting(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Return to Sport Testing</title>
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} header={"Return to Sport Testing"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"max-w-5xl w-full lg:px-4 mx-auto italic"}>Does this sound like you?</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>You have gone through your prescribed physical therapy but you are still unsure of how you will be confident when you get back in the game. </p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Though your surgeon has done table tests and said you’re good to go, you know something is missing.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>You feel like you haven’t been tested and/or exposed to things that stress your body similar to your sport, so you don’t know if you are ready to go again. </p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>You have done all of the exercises and stretches but don’t really know what to do next to get over the hump and back to competing again.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>It feels like you are spinning your wheels and haven’t figured out EXACTLY what you need to work on to get the most out of your body. This is where our Return to Sport Testing comes in. </p>
                    <div className={"lg:w-1/2 w-full h-fit pt-12 mx-auto"}>
                        <img className={"rounded"} src={image3}/>
                    </div>
                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8 pb-3"}>What traditional Return to Sport is Missing</h2>
                    <ul className={"lg:pt-4 pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                        <li  className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Objective measures to track the progress of the physical qualities needed for your sport (not just range of motion, table tests and bodyweight exercises).</li>
                        <li  className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Physical therapists or clinicians with strength and conditioning backgrounds that understand the demands of your sport.</li>
                        <li  className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— A comprehensive and collaborative plan that is designed to get you stronger and more confident than pre-injury.&nbsp;</li>
                        <li  className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— The level of challenge your body needs that matches or exceeds your sport to vaccinate your body from re-injury as best as possible.</li>
                        <li  className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Enough objective data relative to your sport to have a clear understanding of what needs to be worked on to get back to your sport CONFIDENTLY without the guesswork.</li>
                    </ul>
                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-10"}>Dynamometer</h2>
                    <div className={"flex lg:flex-wrap flex-wrap-reverse flex-row max-w-5xl w-full"}>
                        <div className={"lg:w-2/3 w-full lg:p-8 p-2"}>

                            <p className={"pt-4  max-w-5xl w-full lg:px-4 mx-auto"}>This tells us how much force one can produce and how quickly one can produce it. This is important for a few reasons:</p>
                            <ul className={"lg:pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>1. Getting a baseline of strength prior to surgery</li>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>2. Objectively measuring amount and speed of force </li>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>3. Test strength of individual muscle groups</li>
                            </ul>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>The earlier you test the strength of the non-injured leg the better. This baseline testing should be done before any weakness happens due to lessened or complete inactivity. This then serves as the target for the surgically repaired knee. This is what the pros do, so why not you? This is something we offer free of charge for an athlete waiting for surgery. We are extremely passionate about raising the standards to decrease risks of reinjury so contact us if you or someone you know is interested.</p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Force is the name of the game when it comes to sports. Essentially all team sports are about attacking or evading space as well as in some cases throwing/catching an object. What do all of these have in common? The more force one can create the faster the athlete can achieve these tasks. </p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>The second reason is we can test individual muscle groups which can detect weaknesses that go unseen in traditional return to play testing like hop tests. Athletes are incredible compensators so being able to test the strength of individual muscles (as well as global tests) is crucial for return to play criteria. </p>

                        </div>
                        <div className={"lg:w-1/3 w-full h-fit pt-12"}>
                        <img className={"rounded"} src={image1}/>
                        </div>
                    </div>
                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8"}>Force Plate</h2>
                    <div className={"flex flex-wrap flex-row max-w-5xl w-full "}>
                        <div className={"lg:w-1/3 w-full h-fit pt-12"}>
                            <img className={"rounded"} src={image2}/>
                        </div>
                        <div className={"lg:w-2/3 w-full lg:p-8 p-2"}>

                            <p className={"pt-4  max-w-5xl w-full lg:px-4 mx-auto"}>The force plates are an incredible tool that have now become more available for facilities like us. This technology gives us the ability to have a professional level sports medicine lab’s worth of data guide us to make smart training decisions from how intense sessions should be to exactly how we want to load the body to get the most out of training. Here are the main reasons we use the force plates.</p>
                            <ul className={"lg:pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>1. Readiness and fatigue monitoring</li>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>2. Comparing one leg to another </li>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>3. How high and fast an athlete can get off the ground</li>
                                <li  className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>4. Monitor landing mechanics and how an athlete absorbs forces</li>
                            </ul>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>One of the most important aspects of training or rehab is knowing when to go hard and when to take it easy. A quick look at how an athlete is jumping along with a conversation guides the coach or practitioner on how strenuous a session should be (this is true individualization and vital to speed the rehab process). </p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Being able to objectively compare the non-surgical and surgical legs (or arm) is another key component to the rehab process to decrease risk of reinjury (especially in the later stages of rehab). The force plates not only look at how high an athlete can jump, but it can gather the data that tells us how that athlete pushes off the ground and how they absorb the forces when landing. </p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>This detailed data curtails the athlete’s individualized program and is why every one of our practitioners has extensive knowledge in both rehab and strength and conditioning.</p>

                        </div>

                    </div>
                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-5 pb-5"}>Strength and Conditioning</h2>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Developing the general physical qualities like strength, mobility, speed, power, work capacity (repeatability) is what gives an athlete the best chance to have a long and healthy career whether coming back from injury or completely healthy. </p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>We are extremely passionate about this and it is why you will not see run of the mill exercises that everyone gets, a sheet of random exercises or any other low level care. Because all of our practitioners are both knowledgeable and passionate about strength and conditioning, everyone we work with gets an individualized program to get them feeling the most resilient they have felt in their career.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>It is our mission to give people the tools they need to not only get back in the game, but to become more resilient and confident than ever before. Let’s get you back on track and feel what it is like to rehab like the pros. </p>


                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>
                    {/*<h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>What is the Process?</h2>*/}
                    {/*<div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>*/}
                    {/*    <img className={"lg:w-1/5 w-full"} src={image3}/>*/}
                    {/*    <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>*/}
                    {/*        <h3 className={"text-center text-3xl pb-4 pt-4"}>Free Discovery Call</h3>*/}
                    {/*        <p>We want to hear your story, see if what you’re dealing with is something we have seen before, and is something that we can provide you long term relief with. The goal is to make sure you get the right care you need, the best clinician on our team for you, and it starts with getting to know you on a personal level. </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>*/}
                    {/*    <img className={"lg:w-1/5 w-full"} src={image4}/>*/}
                    {/*    <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>*/}
                    {/*        <h3 className={"text-center text-3xl pb-4 pt-4"}>Share Your Story</h3>*/}
                    {/*        <p>We meet so many people frustrated with the care they've been given simply because they have not been heard. It is our number 1 goal to make sure we hear your story and fully understand where you are currently at and where you want to go. This allows us to cater to YOU and help you remove limitations. Your strength is your health and we are here to help you become more resilient than you have ever been! </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>*/}
                    {/*    <img className={"lg:w-1/5 w-full"} src={image1}/>*/}
                    {/*    <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>*/}
                    {/*        <h3 className={"text-center text-3xl pb-4 pt-4"}>Collaborative Assessment and Game-Plan</h3>*/}
                    {/*        <p>We will explore movement together so we can gather enough information to put together a strategy both short and long term to reach your goals. This allows you to be in the driver’s seat for a change while we give you some direction from our experience and expertise. Your health requires you to play an active role and we are here to show you how to get the most out of your body. </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>*/}
                    {/*    <img className={"lg:w-1/5 w-full"} src={image2}/>*/}
                    {/*    <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>*/}
                    {/*        <h3 className={"text-center text-3xl pb-4 pt-4"}>Execute the Plan with Support </h3>*/}
                    {/*        <p>Change is hard and can be very overwhelming. This is why if and when you commit to working with us, we are committed to being in your corner - you become family. When you are challenging your body to become more resilient, there will be good and bad days. In our experience, navigating these ups and downs with an expert is a massive advantage. This is the level of care CEO’s and Pros get, so why shouldn’t you?</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}
                    {/*<h2 className={"lg:text-5xl text-4xl text-center  pt-16 pb-10"}>What you get when you join our family</h2>*/}
                    {/*<Collapsible title={"Education to get you back to exercising"}>*/}
                    {/*    <div className={"flex flex-col justify-center"}>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Does this sound like you… I don’t know why every time I try to exercise I keep getting hurt. I have literally looked up every exercise or stretch under the sun but NOTHING works! When I ask for help everyone tells me something different so now I have no idea what to believe. I know exercise is important but I don’t want to do anything to make it worse. </p>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>What if you had a team of professionals to teach you the KEYS to getting over the hump just like these people? Would you be willing to put on the blinders to become pain free?</p>*/}
                    {/*    </div>*/}
                    {/*</Collapsible>*/}
                    {/*<Collapsible title={"Long Term RESULTS"}>*/}
                    {/*    <div className={"flex flex-col justify-center"}>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>There are so many people that we see that have spent thousands of dollars on chiropractors, physical therapists, massage therapists and only seen temporary relief… and this KILLS us. We help you understand why you are experiencing your pain as well as strategies that provide long lasting results. </p>*/}
                    {/*    </div>*/}
                    {/*</Collapsible>*/}
                    {/*<Collapsible title={"A clinician in your corner even between sessions"}>*/}
                    {/*    <div className={"flex flex-col justify-center"}>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>The road to recovery can be incredibly overwhelming. Stop losing sleep because you are so overwhelmed by wondering if this will get better. We are here for you. We will be here for you every step of the way with guidance and support to make sure you are feeling stronger than you have ever felt before! You will have access via text or email with our clinicians so you have the support you need and deserve. </p>*/}
                    {/*    </div>*/}
                    {/*</Collapsible>*/}
                    {/*<Collapsible title={"An actionable game plan with achievable goals"}>*/}
                    {/*    <div className={"flex flex-col justify-center"}>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Have you seen your doctor and they just gave you some pills and told you to stop doing everything? Though it may be with good intentions, this doesn’t help. We will work with you to put an actionable game plan together that will help you get back to doing things you love to do. </p>*/}
                    {/*    </div>*/}
                    {/*</Collapsible>*/}
                    {/*<Collapsible title={"State of the Art Training Facility in 4S Ranch"}>*/}
                    {/*    <div className={"flex flex-col justify-center"}>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>The future of healthcare will be teaching people how to train for the activities they love and this is why our office is located within a beautiful gym, Optimum Training and Performance. We will have access to anything you need to prepare your body for the activities you love to do. </p>*/}
                    {/*    </div>*/}
                    {/*</Collapsible>*/}
                    {/*<Collapsible title={"Work With us in the Comfort of Your Own Gym Virtually"}>*/}
                    {/*    <div className={"flex flex-col justify-center"}>*/}
                    {/*        <p className={"pt-4 pb-4 max-w-5xl px-4 mx-auto"}>We have been working with people all over the world for years. The approach we take is movement-based so we are able to guide you through things with a hands-off approach. We have helped hundreds of people get out of pain, avoid surgeries, and do it without actually ever seeing us in person.</p>*/}
                    {/*    </div>*/}
                    {/*</Collapsible>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}
                    {/*<div className={"mb-4"}></div>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>What if we told you that you could wake up every morning feeling confident in how strong and resilient your body is? Would you believe us?</p>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>If you were given a plan that could put the days where you have to worry about whether today is going to be a “bad day” behind you, would you be willing to put in the work to get it done?</p>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>We are confident that we can help, but don’t just take it from us…</p>*/}
                    {/*<img src={ChiroReview1} alt={'review'}/>*/}
                    {/*<img src={ChiroReview2} alt={'review'}/>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                    {/*<h2 className={"text-3xl text-center py-14"}>Nagging injuries putting life on hold?</h2>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>How would you feel if you had the SECRETS to overcoming the emotional rollercoaster of the small injuries that keep you from hitting your fitness goals? That frustration of not knowing if you can spend quality time with friends and family without having to worry if your body is going to act up on you again.</p>*/}
                    {/*<img src={ChiroReview3} alt={'review'}/>*/}
                    {/*<img src={ChiroReview4} alt={'review'}/>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                    {/*<h2 className={"text-3xl text-center py-14"}>Is exercise hurting you?</h2>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>Does this sound like you… I don’t know why every time I try to exercise I keep getting hurt. I have literally looked up every exercise or stretch under the sun but NOTHING works! When I ask for help everyone tells me something different so now I have no idea what to believe. I know exercise is important but I don’t want to do anything to make it worse. </p>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>What if you had a team of professionals to teach you the KEYS to getting over the hump just like these people? Would you be willing to put on the blinders to become pain free?</p>*/}
                    {/*<div className={"flex flex-row"}>*/}
                    {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview5} alt={'review'}/>*/}
                    {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview6} alt={'review'}/>*/}
                    {/*</div>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                    {/*<h2 className={"text-3xl text-center py-14"}>We provide you with more than just a “quick fix”!</h2>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>There are so many people that we see that have spent thousands of dollars on chiropractors, physical therapists, massage therapists and only seen temporary relief… and this KILLS us.*/}
                    {/*    We want you to meet Cheryl, who we had the amazing opportunity to help on her journey. She had gone through the ringer of seeing so many specialists but was never able to get answers or long term relief. Here is how we helped her.*/}
                    {/*</p>*/}
                    {/*<img src={ChiroReview7} alt={'review'}/>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                    {/*<h2 className={"text-3xl text-center py-14"}>The road to recovery can be OVERWHELMING.</h2>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>Stop losing sleep because you are so overwhelmed by wondering if this will get better. We are here for you.</p>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>We will be here for you every step of the way with guidance and support to make sure you are feeling stronger than you have ever felt before!</p>*/}
                    {/*<div className={"flex flex-row"}>*/}
                    {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview8} alt={'review'}/>*/}
                    {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview9} alt={'review'}/>*/}
                    {/*</div>*/}

                    {/*<h2 className={"text-3xl text-center py-14"}>We are not your average clinic.*/}
                    {/*</h2>*/}
                    {/*<p className={"pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>Have you seen your doctor and they just gave you some pills and told you to stop doing everything? Do you feel like your doctor is not listening to you and you are receiving cookie cutter care that does not take into account who you are and what you want to do? If so, we are not surprised and we are here to help!</p>*/}
                    {/*<img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview10} alt={'review'}/>*/}
                    {/*<img src={ChiroReview11} alt={'review'}/>*/}
                    {/*<img src={ChiroReview12} alt={'review'}/>*/}
                    {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                </div>
            </Hero>



            <Footer/>
        </div>
    )
}

export default ReturnToSportTesting